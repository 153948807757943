<template lang="html">
  <div class="card card-primary card-outline PanoList">
    <div class="overlay" v-if="$store.state.loading">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
    <div class="card-header p-2">
      <h3 class="card-title">街景管理</h3>
      <div class="card-tools mr-0">
        <div class="input-group input-group-sm">
          <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
          <div class="input-group-append">
            <button type="button" class="btn btn-primary" v-on:click="search()">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body p-1">
      <div class="mailbox-controls p-0">
        <button class="btn btn-sm btn-primary mx-1" title="添加" @click="Create()"><i class="fa fa-plus"></i></button>
        <button class="btn btn-sm btn-default mx-1" title="刷新" v-if="query.type=='list'" @click="loadData()"><i class="fas fa-sync"></i></button>
        <button class="btn btn-sm btn-default mx-1" title="刷新" v-if="query.type=='map'" @click="loadPoints()"><i class="fas fa-sync"></i></button>
        <button class="btn btn-sm btn-warning mx-1" title="地图" v-if="query.type!='map'" @click="query.type='map'"><i class="fas fa-map-marked-alt"></i></button>
        <button class="btn btn-sm btn-warning mx-1" title="列表" v-if="query.type!='list'" @click="query.type='list'"><i class="fas fa-table"></i></button>
        <div class="float-right">
          <div class="dropdown">
            <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              筛选
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" style="z-index:1100">
              <form class="dropdown-item p-1">
                <div class="input-group input-group-sm ">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">标签</span>
                  </div>
                  <select class="form-control form-control-sm" v-model:value="query.tags" v-on:change="search()" placeholder="标签">
                    <option value="">所有</option>
                    <option v-for="i in tags" v-bind:value="i.name">{{i.name}}</option>
                  </select>
                </div>
              </form>
              <form class="dropdown-item p-1">
                <div class="input-group input-group-sm ">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">起始</span>
                  </div>
                  <input type="date" class="form-control form-control-sm" v-model="query.beginTime" v-on:change="search()">
                </div>
              </form>
              <form class="dropdown-item p-1">
                <div class="input-group input-group-sm ">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">结束</span>
                  </div>
                  <input type="date" class="form-control form-control-sm" v-model="query.endTime" v-on:change="search()">
                </div>
              </form>
              <form class="form-control-sm text-nowrap p-1">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioHasGps1" name="customRadioHasGps" class="custom-control-input" v-model="query.hasGps" :value="true" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioHasGps1">有GPS</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioHasGps2" name="customRadioHasGps" class="custom-control-input" v-model="query.hasGps" :value="false" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioHasGps2">无GPS</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioHasGps3" name="customRadioHasGps" class="custom-control-input" v-model="query.hasGps" :value="null" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioHasGps3">不限</label>
                </div>
              </form>
              <form class="form-control-sm text-nowrap p-1">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioPrivate1" name="customRadioPrivate" class="custom-control-input" v-model="query.private" :value="true" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioPrivate1">私有</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioPrivate2" name="customRadioPrivate" class="custom-control-input" v-model="query.private" :value="false" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioPrivate2">公开</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioPrivate3" name="customRadioPrivate" class="custom-control-input" v-model="query.private" :value="null" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioPrivate3">不限</label>
                </div>
              </form>
              <form class="form-control-sm text-nowrap p-1">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioFine1" name="customRadioFine" class="custom-control-input" v-model="query.fine" :value="true" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioFine1">精华</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioFine2" name="customRadioFine" class="custom-control-input" v-model="query.fine" :value="false" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioFine2">普通</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioFine3" name="customRadioFine" class="custom-control-input" v-model="query.fine" :value="null" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioFine3">不限</label>
                </div>
              </form>
              <form class="form-control-sm text-nowrap p-1">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioFeatured1" name="customRadioFeatured" class="custom-control-input" v-model="query.featured" :value="true" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioFeatured1">精选</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioFeatured2" name="customRadioFeatured" class="custom-control-input" v-model="query.featured" :value="false" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioFeatured2">默认</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioFeatured3" name="customRadioFeatured" class="custom-control-input" v-model="query.featured" :value="null" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioFeatured3">不限</label>
                </div>
              </form>
              <form class="form-control-sm text-nowrap p-1">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioClosed1" name="customRadioClosed" class="custom-control-input" v-model="query.closed" :value="true" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioClosed1">关闭</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioClosed2" name="customRadioClosed" class="custom-control-input" v-model="query.closed" :value="false" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioClosed2">打开</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioClosed3" name="customRadioClosed" class="custom-control-input" v-model="query.closed" :value="null" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioClosed3">不限</label>
                </div>
              </form>
              <!--<form class="dropdown-item p-1">
                  <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="customSwitchPrivate" v-model="query.private" v-on:change="search()">
                      <label class="custom-control-label" for="customSwitchPrivate">私有</label>
                  </div>
              </form>
              <form class="dropdown-item p-1">
                  <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="customSwitchFine" v-model="query.fine" v-on:change="search()">
                      <label class="custom-control-label" for="customSwitchFine">精华</label>
                  </div>
              </form>
              <form class="dropdown-item p-1">
                  <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="customSwitchFeatured" v-model="query.featured" v-on:change="search()">
                      <label class="custom-control-label" for="customSwitchFeatured">精选</label>
                  </div>
              </form>-->
            </div>
          </div>
        </div>
        <!-- /.pull-right -->
        <ul v-if="query.type=='list'" class="nav nav-tabs nav-nowrap my-1">
          <li class="nav-item">
            <a href="javascript:" class="nav-link" :class="{'active':query.state==null}" @click="query.state=null;search()">
              全部
            </a>
          </li>
          <li v-for="i in status" class="nav-item">
            <a href="javascript:" class="nav-link" :class="{'active':query.state==i.value}" @click="query.state=i.value;search()">
              {{i.name}}
            </a>
          </li>
        </ul>
      </div>
      <div v-if="query.type=='list'" class="row">
        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3" v-for="i in list">
          <div class="card card-widget widget-user-2">
            <!-- Add the bg color to the header using any of the bg-* classes -->
            <a class="widget-user-header bg-yellow" style="height: 150px;" v-bind:style="resizeimg(i.firstImage?i.firstImage.url:'')" :href="setting.siteUrl+'/Street?panoId='+i.key" target="_blank" :class="{disabled:i.state<=1}">
              <!-- /.widget-user-image -->
              <h4 class="">{{i.title}}</h4>
              <h5 class="">{{i.user.nickName}}</h5>
              <h6 v-if="(i.gpsLat!=0 || i.gpsLng!=0)&&i.country" class="">
                <i class="fas fa-map-marker-alt"></i>&nbsp;
                <span v-if="i.country!='中华人民共和国'&&i.country!='中国'" style="white-space: nowrap;">{{i.country}}</span>
                <span style="white-space: nowrap;">{{i.province}}</span>
                <span style="white-space: nowrap;">{{i.city}}</span>
                <span style="white-space: nowrap;">{{i.district}}</span>
                <span style="white-space: nowrap;">{{i.street}}</span>
              </h6>
              <h6 class="widget-info px-2">
                <span title="观看"><i class="fas fa-eye"></i> {{i.viewCount}}</span>
                <span title="点赞"><i class="fas fa-thumbs-up"></i> {{i.praiseCount}}</span>
                <span><i class="fas fa-map-marker-alt" title="地图坐标" v-if="i.gpsLat!=0 || i.gpsLng!=0"></i></span>
                <span>{{i.status}}</span>
                <span v-if="i.private">私有</span>
                <span v-if="i.fine" class="text-warning">精</span>
                <span v-if="i.featured"><i class="fas fa-star"></i></span>
                <span>{{i.checkNote}}</span>
                <time class="float-right" :title="i.addTime">{{$moment(i.addTime).fromNow()}}</time>
              </h6>
            </a>
            <div class="card-footer p-0">
              <ul class="nav nav-stacked">
                <li class="">
                  <button v-if="$inRole('管理员')" class="btn btn-sm btn-default m-1" @click="addTopItem(i.key)" :disabled="i.state<=1">
                    <i class="fas fa-thumbtack"></i>
                  </button>
                  <!--<button class="btn btn-sm btn-default m-1">
                    <i class="fa fa-qrcode"></i>
                  </button>-->
                  <button class="btn btn-sm btn-warning m-1" @click="showDialog(i)" :disabled="i.state<=1">
                    审核
                  </button>
                  <!--<button class="btn btn-sm btn-warning m-1" @click="showDialog(i)">
                      加精
                  </button>-->
                  <button class="btn btn-sm btn-danger m-1" @click="delDialog(i)" :disabled="i.state==1">
                    删除
                  </button>
                  <button class="btn btn-sm btn-warning m-1" @click="$router.push({ name: 'PublicPanoEdit', params: { panoId: i.guid, ...$route.params  }})" :disabled="i.state<=1">
                    修改
                  </button>
                  <a class="btn btn-sm btn-primary m-1" title="分享" :href="setting.siteUrl+'/Street?panoId='+i.key" target="_blank" :class="{disabled:i.state<=1}">
                    <i class="fas fa-share-square"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div v-if="query.type=='map'" style="position:relative;height:calc(100vh - 170px);margin-top:3px">
        <LeafletMap ref="map" :onMapReady="onMapReady" :mapConfig="mapConfig"></LeafletMap>
      </div>

    </div>
    <div v-if="query.type=='list'" class="card-footer p-2">
      <paging :page-index="pager.pageIndex"
              :total="pager.totalRecords"
              :page-size="pager.pageSize"
              :per-pages="3"
              :show-go="true"
              :prePageSize="pager.prePageSize"
              :onPagerChange="pageChange">
      </paging>
    </div>
    <Modal ref="checkModal">
      <template slot="header">
        <h4 class="modal-title" id="checkModalLabel">作品审核</h4>
      </template>
      <Krpano :pano-id="item.guid" :ratio="1" :krpanoSettings="{mwheel: true,vars:{'autorotate.enabled':true}}" ref="Krpano">
        <div style="pointer-events:none" class="m-1">
          <h6 class="text-shadow" v-text="item.title"></h6>
        </div>
      </Krpano>
      <div id='example-3'>

        <!--<input type="checkbox" id="jack" value="Jack" v-model="checkedNames">
        <label for="jack">标题不合规范</label>
        <br>
        <input type="checkbox" id="john" value="John" v-model="checkedNames">
        <label for="john">内容不合规范</label>
        <br>
        <input type="checkbox" id="mike" value="Mike" v-model="checkedNames">
        <label for="mike">全景图不合规范</label>
        <br>
        <span>Checked names: {{ checkedNames }}</span>-->
      </div>
      <template slot="footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">关闭</button>
        <div>
          <button v-if="!item.private&&!item.fine" type="button" class="btn btn-warning mx-1" @click="fine(item)" :disabled="updateing">设精</button>
          <button v-if="item.fine" type="button" class="btn btn-warning mx-1" @click="unFine(item)" :disabled="updateing">取精</button>
          <button v-if="!item.close" type="button" class="btn btn-danger mx-1" @click="close(item)" :disabled="updateing">屏蔽</button>
          <button v-if="item.close" type="button" class="btn btn-danger mx-1" @click="unClose(item)" :disabled="updateing">取消屏蔽</button>
          <button v-if="!item.private&&item.state==2" type="button" class="btn btn-primary mx-1" @click="check(item)" :disabled="updateing">收录</button>
          <button v-if="item.state==3" type="button" class="btn btn-primary mx-1" @click="unCheck(item)" :disabled="updateing">撤销审核</button>
          <button v-if="item.state==4" type="button" class="btn btn-primary mx-1" @click="unCheck(item)" :disabled="updateing">撤销收录</button>
        </div>
      </template>
    </Modal>

    <Modal ref="uploadModal">
      <template slot="header">
        <h4 class="modal-title" id="uploadModalLabel">上传全景</h4>
      </template>
      <UploadBox :action="uploadUrl"
                 :param="param"
                 :multiple="true"
                 :maxSize="50"
                 :maxWidth="22000"
                 :maxHeight="11000"
                 :isPano="true"
                 :allowExtensions="/(.jpg|.jpeg)$/i"
                 :partSizeMB="10"
                 :surlheight="100"
                 :surlwidth="200"
                 :maxUpper="3"
                 :uploadFinished="search"
                 :changeName="changePanoName">
      </UploadBox>
      <template slot="footer">
      </template>
    </Modal>

    <Modal ref="delModal">
      <template slot="header">
        <h4 class="modal-title">删除全景</h4>
      </template>
      确定删除 {{(item||{}).title}} ?
      <template slot="footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
        <button type="button" class="btn btn-primary" @click="del(item)" :disabled="updateing">确定</button>
      </template>
    </Modal>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import {
    UploadBox,
    Modal,
    Krpano,
    LeafletMap,
  } from 'common'
  import MapPopupInfo from '@/components/MapPopupInfo'

  export default {
    components: {
      UploadBox,
      Modal,
      Krpano,
      LeafletMap,
    },
    data() {
      return {
        item: {},
        checkedNames: [],
        publicTags: [],
        privateTags: [],
        status: [
          { name: '待切片', value: 0 },
          { name: '切片中', value: 1 },
          { name: '待收录', value: 2 },
          { name: '已收录', value: 4 },
          { name: '未收录', value: 3 },
        ],
        pager: {
          prePageSize: [12, 24, 48, 96],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: {
          type: 'list',
          pageIndex: 1,
          pageSize: 12,
          sort: "Id",
          order: "desc",
          fields: null,
          key: null,
          tags: null,
          //innId: this.$route.params.innId,
          private: null,
          fine: null,
          featured: null,
          closed: null,
          hasGps: null,
          beginTime: null,
          endTime: null,
        },
        files: [],
        progress: '0%',
        showProgress: false,
        showProgressErro: false,
        ProgressErro: '',
        fileList: [],
        param: { innId: this.$route.params.innId },
        list: [],
        updateing: false,
        uploadUrl: `/Api/PublicAdmin/Panos/Upload/${this.$route.params.category}`,
        mapConfig: {
          zoom: 4,
        },
        panoPoints: [],
      }
    },
    created() {
      this.loadData()
      //this.$store.state.pageinfo.main.title = "用户管理"
      //this.setPageInfo("用户管理")

    },
    computed: {
      ...mapGetters([
        'setting'
      ]),
      tags: function () {
        return [...this.publicTags, ...this.privateTags]
      },
    },
    methods: {
      loadData() {
        this.loadTags()
        var url = window.global.ENABLE_ELASTICSEARCH ? `/Api/ES/InnAdmin/Panos` : `/Api/InnAdmin/Streets`;
        this.$axios.get(url, {
          params: {
            page: this.query.pageIndex,
            pageSize: this.query.pageSize,
            sort: this.query.sort,
            order: this.query.order,
            fields: this.query.fields,
            key: this.query.key,
            tags: this.query.tags,
            category: this.$route.params.category,
            state: this.query.state,
            private: this.query.private,// ? true : null,
            fine: this.query.fine,// ? true : null,
            featured: this.query.featured,// ? true : null,
            closed: this.query.closed,// ? true : null,
            hasGps: this.query.hasGps,
            beginTime: this.query.beginTime,
            endTime: this.query.endTime,
          }
        }).then((res) => {
          this.pager.pageIndex = res.data.pageIndex;
          this.pager.pageSize = res.data.pageSize;
          this.pager.totalPages = res.data.totalPages;
          this.pager.totalRecords = res.data.totalRecords;
          this.list = res.data.data;
        })
      },
      loadTags() {
        var tagtype = 11;
        //this.$axios.get(`/Api/InnAdmin/Tags/All/${this.$route.params.innId}`, { params: { type: tagtype } })
        //    .then((response) => {
        //        //console.log(response)
        //        this.privateTags = response.data
        //    })
        //    .catch((error) => {
        //        console.log(error)
        //    })
        this.$axios.get('/Api/Tags/All', { params: { type: tagtype } })
          .then((response) => {
            //console.log(response)
            this.publicTags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      search() {
        this.query.pageIndex = 1;
        this.loadData()
      },
      pageChange(pageIndex, pageSize) {
        this.query.pageIndex = pageIndex;
        this.query.pageSize = pageSize;
        this.loadData();
      },
      sortingChanged(ctx) {
        // ctx.sortBy   ==> Field key for sorting by (or null for no sorting)
        // ctx.sortDesc ==> true if sorting descending, false otherwise
        this.query.sort = ctx.sortBy;
        this.query.order = ctx.sortDesc ? "desc" : "asc";
        this.loadData();
      },
      resizeimg(url) {
        return {
          'background-image': 'url(' + url.replace('/0/0/0/0/', '/600/0/0/0/') + ')',
          'background-size': 'cover',
          'background-position': 'center',
          'background-repeat': 'no-repeat',

        }
      },
      onMapReady() {
        this.loadPoints()
        L.DomEvent.on(this.$refs.map.leafletMap, 'baselayerchange', (e) => {
          this.$nextTick((e) => {
            this.renewPoints()
          })
        }, this)
      },
      renewPoints() {
        var markconfigs = [...this.panoPoints]
        for (var i in markconfigs) {
          var item = markconfigs[i]
          markconfigs[i].draggable = true
          markconfigs[i].position = { lat: item.lat, lng: item.lng }
          var c = (event) => {
            this.$refs.map.addMarkerPopup(event.target, {
              content: `加载中…`,
            })
            //var panoUrl = '/Api/InnAdmin/Streets/' + event.target.extData
            var panoUrl = '/Api/ES/Panos/' + event.target.extData
            this.$axios.get(panoUrl).then((res) => {
              //var p = this.transformLoc(res.data.gpsLat, res.data.gpsLng)
              //console.log(p)
              this.$refs.map.addMarkerPopup(event.target, {
                content: this.panoInfowindow(res.data, event.target),
              })
            })
            //this.$refs.map.addInfoWindow('infoWindow', {
            //  position: event.lnglat,
            //  content: `加载中…`,
            //  closeWhenClickMap: true,
            //  anchor: 'top-center',
            //})
            //this.$axios.get('/Api/InnAdmin/Streets/' + event.target.getExtData()).then((res) => {
            //  var p2 = gpsCorrect.transform(res.data.gpsLat, res.data.gpsLng)
            //  console.log(res.data, p2)
            //  this.$refs.map.addInfoWindow('infoWindow', {
            //    position: [p2.lng, p2.lat],
            //    content: this.panoInfowindow(res.data, new AMap.LngLat(p2.lng, p2.lat)),
            //    closeWhenClickMap: true,
            //    anchor: 'top-center',
            //  })
            //})
          }
          markconfigs[i].on = {
            click: c,
            dragend: c
          }
          markconfigs[i].extData = this.panoPoints[i].key
        }
        this.$refs.map.addMarkerClusterer(markconfigs, {
          maxZoom: 20,
          on: {
            click: (event) => {
              console.log(event)
            }
          }
        }, 'panoPoint')

      },
      loadPoints() {
        //this.$axios.get("/Api/InnAdmin/Streets/PanoLocations", {
        this.$axios.get("/Api/ES/Panos/Locations", {
          params: {
            //cached: false,
            ...this.query,
            category: this.$route.params.category,
          }
        }).then((res) => {
          this.panoPoints = res.data.data
          this.renewPoints()
        }).catch((err) => {
          console.error(err)
        })
      },
      panoInfowindow(pano, marker) {
        var vm = new Vue(MapPopupInfo)
        vm.pano = pano
        vm.marker = marker
        vm.$on('审核', (i) => {
          this.showDialog(i)
        })
        vm.$on('编辑', (i) => {
          this.$router.push({ name: 'PublicPanoEdit', params: { panoId: i.guid, ...this.$route.params } })
        })
        vm.$on('移动', (i) => {
          var p = marker.getLatLng()
          this.$axios.post('/Api/PublicAdmin/Panos/Location/' + pano.guid, p)
            .then((response) => {
              pano.gpsLat = p.lat
              pano.gpsLng = p.lng
              marker.closePopup()
              this.$message({
                message: '保存成功',
                type: 'info',
                zIndex: 9999,
              })
            })
            .catch((error) => {
              console.log(error)
            })
        })
        vm.$on('重置', (i) => {
          marker.setLatLng(new L.LatLng(pano.gpsLat, pano.gpsLng))
          marker.closePopup()
        })
        var e = document.createElement('div')
        e.style.width = '200px'
        //setTimeout(() => {
        //  vm.$mount(e)
        //}, 100)
        this.$nextTick(() => {
          vm.$mount(e)
        })
        console.log(vm)
        return e
        //return this.panoListInfowindow([pano])
      },

      showDialog(item) {
        this.$refs.checkModal.show()
        this.item = item
      },
      Create() {
        this.$refs.uploadModal.show()
      },
      delDialog(item) {
        this.$refs.delModal.show()
        this.item = item
      },
      del(item) {
        this.updateing = true;
        var url = "/Api/InnAdmin/Panos/" + item.guid
        this.$axios.delete(url)
          .then((response) => {
            console.log('#', response)
            this.loadData()
            this.$refs.delModal.hide()
            this.updateing = false;
          }).catch((error) => {
            console.log(error)
            this.updateing = false;
          })
      },
      addFile(event) {
        this.files = event.target.files[0];
        console.log(this.files);
      },
      changePanoName(item, res) {
        if (res) {
          console.log(item, res)
          var data = { ...res };
          data.title = item.title;
          console.log(data)
          this.$axios.put('/Api/InnAdmin/Panos/' + data.guid, data).then(function (res) {
            if (res.status == 200) {
              this.search()
            }
          })
        }
      },
      check(item) {
        if (this.updateing) {
          return
        }
        this.updateing = true
        this.$axios.post(`/Api/Admin/Panos/Check/${item.guid}`, { check: true }).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: '收录成功',
              type: 'info',
              zIndex: 9999,
            })
            this.loadData()
            this.$refs.checkModal.hide()
            this.updateing = false
          }
        })
      },
      unCheck(item) {
        if (this.updateing) {
          return
        }
        this.updateing = true
        this.$axios.post(`/Api/Admin/Panos/UnCheck/${item.guid}`).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: '撤销收录成功',
              type: 'info',
              zIndex: 9999,
            })
            this.loadData()
            this.$refs.checkModal.hide()
            this.updateing = false
          }
        })
      },
      fine(item) {
        if (this.updateing) {
          return
        }
        this.updateing = true
        this.$axios.get(`/Api/Admin/Panos/Fine/${item.guid}`).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: '设置精华成功',
              type: 'info',
              zIndex: 9999,
            })
            this.loadData()
            this.$refs.checkModal.hide()
            this.updateing = false
          }
        })
      },
      unFine(item) {
        if (this.updateing) {
          return
        }
        this.updateing = true
        this.$axios.get(`/Api/Admin/Panos/UnFine/${item.guid}`).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: '取消精华成功',
              type: 'info',
              zIndex: 9999,
            })
            this.loadData()
            this.$refs.checkModal.hide()
            this.updateing = false
          }
        })
      },
      close(item) {
        if (this.updateing) {
          return
        }
        this.updateing = true
        this.$axios.get(`/Api/Admin/Panos/Close/${item.guid}`).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: '设置精华成功',
              type: 'info',
              zIndex: 9999,
            })
            this.loadData()
            this.$refs.checkModal.hide()
            this.updateing = false
          }
        })
      },
      unClose(item) {
        if (this.updateing) {
          return
        }
        this.updateing = true
        this.$axios.get(`/Api/Admin/Panos/UnClose/${item.guid}`).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: '取消精华成功',
              type: 'info',
              zIndex: 9999,
            })
            this.loadData()
            this.$refs.checkModal.hide()
            this.updateing = false
          }
        })
      }, addTopItem(key) {
        var data = { key: key }
        this.$axios.post('/Api/Admin/TopItem', data).then((res) => {
          this.$message({
            message: '添加置顶成功',
            type: 'info',
            zIndex: 9999,
          })
        }).catch((error) => {
          console.log(error)
        })
      },
    },
    beforeRouteUpdate(to, from, next) {
      next()
      this.uploadUrl = `/Api/PublicAdmin/Panos/Upload/${to.params.category}`
      setTimeout(this.loadData, 1)
    }
    //beforeRouteLeave(to, from, next) {
    //  // 导航离开该组件的对应路由时调用
    //  // 可以访问组件实例 `this`
    //  $('.modal').modal('hide')
    //  next()
    //}
  }
</script>

<style lang="css">
  .PanoList .card-widget .widget-user-header h4 {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    font-size: 1rem;
  }

  .PanoList .card-widget .widget-user-header h5 {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    font-size: 0.8rem;
  }

  .PanoList .card-widget .widget-user-header h6 {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    font-size: 0.7rem;
  }

  .PanoList .card-widget .widget-user-header {
    position: relative;
  }

    .PanoList .card-widget .widget-user-header .widget-info {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

      .PanoList .card-widget .widget-user-header .widget-info span {
        padding: 0 0.2rem;
      }

  .PanoList .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
</style>
