<template>
  <div>
    <div class="mapInfoWin mb-2">
      <div>
        <a class="editPanobtn">
          <img style="width:200px;height:100px;" :src="pano.firstImage.url.replace('/0/0/0/0/', '/200/0/0/0/' )" />
        </a>
        <div class="mapuserinfo"><span class="text-truncate">{{pano.title}}</span></div>
        <div class="mapAction mt-1">
          <button class="btn btn-sm btn-warning mr-1" @click="$emit('审核',pano)" :disabled="pano.state<=1">审核</button>
          <button class="btn btn-sm btn-warning mr-1" @click="$emit('编辑',pano)" :disabled="pano.state<=1">编辑</button>
          <template v-if="moved()">
            <button class="btn btn-sm btn-primary mr-1" @click="$emit('移动',pano)">移动</button>
            <button class="btn btn-sm btn-default" @click="$emit('重置',pano)">重置</button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'MapPopupInfo',
    data: {
      pano: {},
      marker: null,
    },
    created() {
    },
    methods: {
      moved() {
        var p = this.marker.getLatLng()
        return p.lat != this.pano.gpsLat || p.lng != this.pano.gpsLng
      }
    },
    computed: {
    }
  }
</script>

<style lang="css" scoped>
    .mapInfoWin {
      width: 200px;
      position: relative;
    }

    .mapuserinfo {
      position: absolute;
      top: 4px;
      left: 2px;
      right: 2px;
      text-shadow: 0 0 5px #000, 0 0 4px #000, 0 0 3px #000, 0 0 2px #000;
      color: #fff;
    }

    .mapAction {
      position: relative;
      text-align: center;
      /*    bottom: 4px;
      left: 2px;
      right: 2px;
  */ /*    text-shadow: 0 0 5px #000, 0 0 4px #000, 0 0 3px #000, 0 0 2px #000;
        color: #fff;
    */
    }
</style>
